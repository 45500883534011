import React from "react";
import { MainWrapper } from "./elements";
import environment from "environment";

const CASection = () => {
  return (
    <>
    {/* <MainWrapper>
      <h5> <span>ADRESSE DU CONTRACT-ICO :</span><br/>{environment.ARGSALE[environment.DEFAULT_CHAIN]}</h5>
     <h5><span>PARAMETRE VOTRE JETON ARG SUR METAMASK :</span><br/> 0xc7610a6Fb69C7cCb7620E740449E767Dd0c58391</h5>
   </MainWrapper> */}
   </>
  );
};

export default CASection;
