import { aboutimg1, aboutimg2, tree1, tree2 } from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";
import {
  ImgDiv,
  ImgDiv2,
  ImgWrapper,
  MainSection,
  Text1,
  Text2,
  Title,
} from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const AboutComp = () => {
  return (
    <MainSection id="about">
      <MainContainer>
        <AnimationOnScroll animateIn="animate__flipInX">
          <Title>
            ARGANACOIN
            <br />
            <p>SOUTENEZ LE JETON ARG</p>
          </Title>
        </AnimationOnScroll>
        <ImgDiv>
          <Image src={tree1} />
        </ImgDiv>
        <MainRow>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <Text1>
                <ul>
                  <li>
                    Le jeton ARG sera disponible à l'achat pendant l’offre initiale des jetons (ICO) sur une
                    période de 24 mois, avec deux tarifs différents pour chaque
                    année consécutive.
                  </li>
                  <li>
                    Au lancement de l'ICO, ARG sera échangé au prix de 0,25 c/€
                    (0,27 USDT)
                  </li>
                  <li>
                    Des tarifs spéciaux seront offerts aux premiers 500
                    Clients/Investisseurs ayant soutenu l’acquisition de jetons
                    ARG.
                  </li>
                  <li>
                  Notre feuille de route inclut la création d'une plateforme évolutive,
                  semblable au système de « growfunding », permettant le parrainage d'arbres
                  et de divers actifs agricoles via des NFT.
                  </li>
                  {/* <li>
                    Chaque étape de l’avancement des projets sera soutenue par
                    une campagne marketing adaptative et transparente
                  </li> */}
                  <li>
                    Les détenteurs de jetons ARG recevront des récompenses en
                    fonction de leur participation au projet, pour toutes les
                    initiatives de promotion proactives et de communication
                    marketing partagée.
                    La distribution peut être proportionnelle au nombre de jetons détenus.
                  </li>
                  <li>
                    Le programme ambitieux de notre équipe nécessite un calendrier mesuré.
                    Ensemble, construisons progressivement cet écosystème.
                    Rejoignez notre communauté via nos différents canaux de communication.
                  </li>
                </ul>
              </Text1>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <ImgWrapper>
                <Image src={aboutimg1} fluid />
              </ImgWrapper>
            </AnimationOnScroll>
            <ImgDiv2>
              <Image src={tree2} />
            </ImgDiv2>
          </MainCol>
        </MainRow>
        <AnimationOnScroll animateIn="animate__flipInX">
          <Title className="title2">
            ARGANACOIN
            <br />
            <p>Qu'est-ce que ARGANACOIN (ARG) ?</p>
          </Title>
        </AnimationOnScroll>
        <MainRow className="main-row">
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <ImgWrapper className="img-wrapper2">
                <Image src={aboutimg2} fluid />
              </ImgWrapper>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={6}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <Text2>
                <p>
                  <span>ARGANACOIN (ARG)</span> est un projet de cryptomonnaie
                  utilitaire destiné à l'agronomie. Il vise à établir un
                  partenariat gagnant-gagnant avec les domaines agricoles en tirant les
                  nombreux avantages de la technologie Blockchain.
                  <br />
                  <br />
                  Nous conduirons notre token Arganacoin à créer son propre
                  environnement numérique, avec la mise en place de :
                </p>
                <div style={{ marginLeft: "-1rem" }}>
                  <ul>
                    <li>
                      Une plateforme numérique évolutive de croissance maitrisée
                      (SEED IT YOURSELF) : 
                    </li>
                    <li>
                    un système original de parrainage d’arganiers et de diverses
                     variétés agricoles par l’intermédiaire de NFTs.
                    </li>
                    <li>
                      Une MarketPlace (ARGANAGOLD) destinée à l’acquisition de nos NFTs.
                    </li>
                    <li>
                    Soutien à l’optimisation des <span>smart-coopératives</span> ou exploitation
                     connectées grâce à la démocratisation de l’IoT (Internet des objets).
                    </li>
                    <li>
                      Une plateforme E- commerce (ARGANAZONE) exclusivement conçue
                      pour fédérer et promouvoir les marques et labels nationaux,
                      les coopératives ainsi que les petites et moyennes entreprises
                      respectant les normes internationales établies.
                    </li>
                    <li>
                      Actions proactives et Investissements structurels destinés
                      à  la préservation de la Biosphère et des patrimoines
                      forestiers d’arganiers.
                    </li>
                    <li>
                      Soutien et solutions pour le monde rural, les Coopératives
                      agricoles, les ayants droit.
                    </li>
                  </ul>
                </div>
                <h5>
                  (Consultez le Livre Vert Arganacoin - Répartition des jetons
                  ARG)
                </h5>
              </Text2>
            </AnimationOnScroll>
          </MainCol>
        </MainRow>
      </MainContainer>
    </MainSection>
  );
};

export default AboutComp;
