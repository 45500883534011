import React, { useState, useEffect } from "react";
import {
  AmountText,
  Btn,
  BtnDiv,
  FormDiv,
  ImgDiv,
  MainDiv,
  MainSection,
  RightDiv,
  SliderLabel,
  Text,
  TimeLabel,
  TimerDiv,
  ToknomicsBtn,
  TopTitleDiv,
  CostDiv,
} from "./elements";
import {
  MainCol,
  MainContainer,
  MainRow,
  ToastMessage,
} from "components/common";
import Timer from "./timer";
import { Form, Image } from "react-bootstrap";
import { ethicon, heroimg, usdticon } from "assets";
import NavbarComp from "./../navbar/index";
import { useAppSelector } from "store/store";
import { CommonUtility } from "utility/common";
import { argSaleAbi } from "utility/contract/argSaleAbi";
import environment from "environment";
import { erc20Abi } from "utility/contract";
import Loading from "components/common/loaders/loading";
import { Link } from 'react-router-dom';


const HeroComp = () => {
  const [usdprice, setUsdPrice] = useState(null);
  const [priceInEth, setPriceInEth] = useState(null);
  const [soldToken, setSoldTokens] = useState("-");
  const [arganacoinAmount, setArganacoinAmount] = useState(Number);
  const [isLoading, setIsLoading] = useState(false);

  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );
  const handleAmountChange = (event) => {
    setArganacoinAmount(event.target.value);
  };
  const getPriceInEth = async () => {
    try {
      const contract = CommonUtility.contract(
        web3,
        argSaleAbi,
        environment.ARGSALE[chainId]
      );

      let priceEth = await contract.methods
        .tokenPriceInEth(web3.utils.toWei("1", "ether"))
        .call();
      priceEth = +web3.utils.fromWei(priceEth.toString(), "ether");
      console.log("priceEth", priceEth);
      setPriceInEth(priceEth.toFixed(5));
    } catch (error) {
      console.log("getPriceInEth error", error);
    }
  };
  const getPriceInUsd = async () => {
    try {
      const contract = CommonUtility.contract(
        web3,
        argSaleAbi,
        environment.ARGSALE[chainId]
      );
      const priceInWei = await contract.methods.tokenPrice().call();
      const price = +web3.utils.fromWei(priceInWei, "mwei");
      console.log("priceusd", price);

      setUsdPrice(price);
    } catch (error) {
      console.log("getPriceInUsd error", error);
    }
  };
  const getTotalSold = async () => {
    try {
      const contract = CommonUtility.contract(
        web3,
        argSaleAbi,
        environment.ARGSALE[chainId]
      );
      const soldInWei = await contract.methods.tokensSold().call();
      const sold = +soldInWei > 0 ? web3.utils.fromWei(soldInWei, "ether") : 0;
      setSoldTokens(sold);
    } catch (error) {
      console.log("getTotalSold error", error);
    }
  };
  useEffect(() => {
    if (web3) {
      getPriceInUsd();
      getTotalSold();
      getPriceInEth();
    }
  }, [web3]);
  const usdPurchase = async () => {
    try {
      setIsLoading(true);
      if (!web3 || !account) throw "Please connect wallet";

      if (+arganacoinAmount < 1) throw "Invalid Input Amount";
      const saleContract = CommonUtility.contract(
        web3,
        argSaleAbi,
        environment.ARGSALE[chainId]
      );
      const startTime = await saleContract.methods.presaleStartTime().call();
      const endTime = await saleContract.methods.presaleEndTime().call();
      if (Date.now() / 1000 <= +startTime) throw "Presale hasn't started";
      if (Date.now() / 1000 >= +endTime) throw "Presale ended";
      const contract = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.USDT[chainId]
      );

      const userBalanceInWei = await contract.methods.balanceOf(account).call();
      const userBalance = web3.utils.fromWei(userBalanceInWei, "mwei");
      const allowanceInWei = await contract.methods
        .allowance(account, environment.ARGSALE[chainId])
        .call();
      const allowance = web3.utils.fromWei(allowanceInWei, "mwei");
      const requiredAmount = arganacoinAmount * usdprice;
      if (+userBalance < +requiredAmount) throw "Insufficient USDT Balance";
      const argCoin = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.ARGANACOIN[chainId]
      );
      const balanceInWei = await argCoin.methods
        .balanceOf(environment.ARGSALE[chainId])
        .call();
      const balance = web3.utils.fromWei(balanceInWei, "ether");
      if (arganacoinAmount > balance) throw "All tokens Sold";
      if (+allowance < +requiredAmount) {
        const approval = await contract.methods
          .approve(environment.ARGSALE[chainId], environment.UINT_256_MAX)
          .send({ from: account });
        if (approval && approval.code == 4001)
          throw "User denied the transaction";
        if (!approval.status) throw "Transaction Failed";
      }

      const txn = await saleContract.methods
        .buyTokensInUSDT(web3.utils.toWei(arganacoinAmount.toString(), "ether"))
        .send({ from: account });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        await getTotalSold();

        setIsLoading(false);
        ToastMessage("Success!", "Transaction Successfull", "success");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("usdPurchase error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const ethPurchase = async () => {
    try {
      setIsLoading(true);
      if (!web3 || !account) throw "Please connect wallet";
      if (+arganacoinAmount < 1) throw "Invalid Input Amount";
      const contract = CommonUtility.contract(
        web3,
        argSaleAbi,
        environment.ARGSALE[chainId]
      );
      const startTime = await contract.methods.presaleStartTime().call();
      const endTime = await contract.methods.presaleEndTime().call();
      if (Date.now() / 1000 <= +startTime) throw "Presale hasn't started";
      if (Date.now() / 1000 >= +endTime) throw "Presale ended";
      const ethBalanceInWei = await web3.eth.getBalance(account);
      const userBalance = web3.utils.fromWei(ethBalanceInWei, "ether");
      const requiredAmount = await contract.methods
        .tokenPriceInEth(web3.utils.toWei(arganacoinAmount.toString(), "ether"))
        .call();
      if (requiredAmount < ethBalanceInWei) throw "Insufficient Balance";
      const argCoin = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.ARGANACOIN[chainId]
      );
      const balanceInWei = await argCoin.methods
        .balanceOf(environment.ARGSALE[chainId])
        .call();
      const balance = web3.utils.fromWei(balanceInWei, "ether");
      if (arganacoinAmount > balance) throw "All tokens Sold";
      const saleContract = CommonUtility.contract(
        web3,
        argSaleAbi,
        environment.ARGSALE[chainId]
      );
      const txn = await saleContract.methods
        .buyTokensInEth(web3.utils.toWei(arganacoinAmount.toString(), "ether"))
        .send({ from: account, value: requiredAmount });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        await getTotalSold();
        setIsLoading(false);
        ToastMessage("Success!", "Transaction Successfull", "success");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("ethPurchase error", error);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  return (
    <MainSection>
      <NavbarComp />
      <MainDiv>
        {isLoading && <Loading></Loading>}
        <MainContainer>
          <MainRow>
            <MainCol lg={7}>
              <TopTitleDiv>ARGANACOIN</TopTitleDiv>
              <Text>
                LA GRAINE NUMÉRIQUE QUI CONSTRUIRA SON ÉCOSYSTÈME UNIQUE ET
                DÉDIÉE À UNE NATURE RÉSILIENTE ET GENEREUSE. NOUS PARRAINERONS
                DES ARBRES ET DIVERSES VARIETES AGRICOLES ISSUS DE LA BIOSPHERE
                MAROCAINE EN DÉVELOPPANT UNE PLATEFORME ÉVOLUTIVE,
                UN ESPACE E-COMMERCE EXCLUSIVEMENT DÉDIÉ AUX COOPÉRATIVES
                AGRICOLES, SOUTENU PAR LA BLOCKCHAIN ET SES NOMBREUSES
                INNOVATIONS TECHNOLOGIQUES. <br /> SEMEZ AUJOURD'HUI… RÉCOLTEZ DEMAIN !
              </Text>
              <a href="#roadmap">
                <ToknomicsBtn >Tokénomique</ToknomicsBtn>
              </a>

            </MainCol>
            <MainCol lg={5}>
              {/* <div className="main-right-div">
                <RightDiv>
                  <ImgDiv>
                    <Image src={heroimg} />
                  </ImgDiv>
                  <TimeLabel>La prévente ARG Crypto est lancée</TimeLabel>
                  <TimerDiv>
                    <Timer />
                  </TimerDiv>
                  <SliderLabel>Jeton épuisé</SliderLabel>
                  <AmountText>{soldToken}</AmountText>
                  <CostDiv>
                    coût estimé (<Image src={usdticon} />) :{" "}
                    {usdprice ? CommonUtility.decFixed((usdprice * arganacoinAmount), 3) : "-"} USDT
                  </CostDiv>
                  <CostDiv>
                    coût estimé (<Image src={ethicon} />) :{" "}
                    {priceInEth ? CommonUtility.decFixed((priceInEth * arganacoinAmount), 6) : "-"} ETH
                  </CostDiv>
                  <FormDiv>
                    <p>Acheter Arganacoin</p>
                    <Form.Control
                      type="number"
                      placeholder="Enter Arganacoin Amount"
                      min={0}
                      value={arganacoinAmount}
                      onChange={handleAmountChange}
                    />
                  </FormDiv>

                  <BtnDiv onClick={usdPurchase} className="btn-div">
                    <Btn>Achetez ARG avec USDT</Btn>
                    <Image src={usdticon} />
                  </BtnDiv>
                  <BtnDiv onClick={ethPurchase}>
                    <Btn>Achetez ARG avec ETH</Btn>
                    <Image src={ethicon} />
                  </BtnDiv>
                </RightDiv>
              </div> */}
            </MainCol>
          </MainRow>
        </MainContainer>
      </MainDiv>
    </MainSection>
  );
};

export default HeroComp;
