export const data = [
  {
    id: 1,
    title: "2022",
    typeData: [
      {
        value: "Constitution du Livre Vert et \n de l’Ecosystème ARGANACON"
      },
      {
        value: "Prospection de Partenariats \n (Maroc)"
      },
      {
        value: "Prospection de Partenariats \n (Blockchain)"
      }
    ],
    text: "Constitution du Livre Vert et de l’Ecosystème ARGANACON \n Prospection de Partenariats (Maroc) \n Prospection de Partenariats (Blockchain)"
  },
  {
    id: 2,
    title: "2023",
    typeData: [
      {
        value: "Création du Smart Contract pour le jeton \n ARGANACON - ARG"
      },
      {
        value: "Marketing & Communication \n pour le Projet ARGANACON"
      },
      {
        value: "Finalisation des Partenariats \n (Maroc)"
      }
    ],
    text: "Création du Smart Contract pour le jeton ARGANACON - ARG \n Marketing & Communication pour le Projet ARGANACON \n Finalisation des Partenariats (Maroc)"
  },
  {
    id: 3,
    title: "2024",
    typeData: [
      {
        value: "Communication et Marketing & ICO 1er tarif \n (Offre Initiale de Jetons)"
      },
      {
        value: "Promotion globale du Projet sur les Réseaux Sociaux"
      },
      {
        value: "Réflexion et architecture de la Plateforme \n « SEED IT YOURSELF »"
      },
      {
        value: "Parrainage des Arganiers via des NFTs"
      }
    ],
    text: "Communication et Marketing & ICO 1er tarif (Offre Initiale de Jetons) \n Promotion globale du Projet sur les Réseaux Sociaux \n Réflexion et architecture de la Plateforme « SEED IT YOURSELF » \n Parrainage des Arganiers via des NFTs"
  },
  {
    id: 4,
    title: "2025",
    typeData: [
      {
        value: "Communication et Marketing  \n & ICO 2ème tarif"
      },
      {
        value: "Création des Smart Contracts NFTs"
      },
      {
        value: "Création de la Plateforme Marketplace NFT \n « ARGANAGOLD »"
      },
      {
        value: "Développement des Projets pour la préservation de l’arganier"
      },
      {
        value: "Présentation de la Plateforme « SEED IT YOURSELF »"
      }
    ],
    text: "Communication et Marketing & ICO 2ème tarif \n Création des Smart Contracts NFTs \n Création de la Plateforme Marketplace NFT « ARGANAGOLD » \n Développement des Projets pour la préservation de l’arganier \n Présentation de la Plateforme « SEED IT YOURSELF »"
  },
  {
    id: 5,
    title: "2026",
    typeData: [
      {
        value: "Réflexion et architecture de la Plateforme « ARGANAZONE » (portail commercial et international dédié aux Coopératives Marocaines)"
      },
      {
        value: "Développement des Réseaux et promotion marchande « ARGANAZONE »"
      },
      {
        value: "Listing du jeton ARGANACOIN sur les Plateformes d’Echanges"
      }
    ],
    text: "Réflexion et architecture de la Plateforme « ARGANAZONE » (portail commercial et international dédié aux Coopératives Marocaines) \n Développement des Réseaux et promotion marchande « ARGANAZONE » \n Listing du jeton ARGANACOIN sur les Plateformes d’Echanges"
  },

];
