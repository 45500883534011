export const Images = {
  web3: {
    metamask: require("./web3/metamask.png"),
    wallet: require("./web3/wallet.png"),
  },
  login: {
    bg: require("./images/bg-img.png"),
  },
};

export { default as logo } from "./images/logo.png";
export { default as usdticon } from "./images/usdt.png";
export { default as ethicon } from "./images/eth.png";
export { default as heroimg } from "./images/heroimg.png";
export { default as herobg } from "./images/herobg.png";
export { default as tree1 } from "./images/tree1.png";
export { default as aboutimg1 } from "./images/aboutimg1.png";
export { default as aboutimg2 } from "./images/aboutimg2.png";

export { default as tree2 } from "./images/tree2.png";
export { default as tree3 } from "./images/tree3.png";
export { default as roadmapimg } from "./images/roadmapimg.png";
export { default as tree4 } from "./images/tree4.png";
export { default as tree5 } from "./images/tree5.png";

export { default as tokenimg } from "./images/tokenimg.png";
export { default as tokennmicsimg } from "./images/tokennomicsimg.png";
export { default as tokennmicsimg1 } from "./images/tokennomicsimg1.png";
export { default as token } from "./images/token.png";
export { default as token01 } from "./images/Vierge_Transparent.png";
export { default as donutmobile } from "./images/DonutGiagrammobile.png";
export { default as donutdesktop } from "./images/DonutGiagramdesktop.png";
export { default as treemobile } from "./images/treemobile.png";
export { default as treedesktop } from "./images/treedesktop.png";

// export { default as treemobile } from "./images/treeillustrationmobile.png";
// export { default as treedesktop } from "./images/treeillustrationdesktop.png";

export { default as helpimg } from "./images/helpimg.png";
export { default as joinimg } from "./images/joinimg.png";
export { default as twittericon } from "./images/twitter.png";
export { default as instaicon } from "./images/insta.png";
export { default as facebookicon } from "./images/facebook.png";
export { default as telegramicon } from "./images/telegram.png";
export { default as linkdenicon } from "./images/linkden.png";
export { default as joinimg2 } from "./images/joinimg2.png";
export { default as scrollimg } from "./images/scollicon.png";
export { default as roadmapresponsive } from "./images/reroadmap.png";
export { default as greenpaper } from "./images/greenpaper.pdf";
export { default as greenpaper2 } from "./images/greenpaper2.pdf";
export { default as isoimg } from "./images/isoimg.png";

export { default as video } from "./images/video.mp4";
export { default as Arganacoin } from "./images/video.mp4";
