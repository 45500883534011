import {
  MainModel,
  ModelsData,
  ConnectButton,
  ConnectIcon,
  MainWrapper,
} from "./connectModalElement";
import { Images } from "../../../../assets";
import {
  loadBlockchain,
  loadWalletConnect,
} from "store/redux/slices/wallet3Connect/web3ConnectSlice";
import { useAppDispatch } from "store/store";
import { useWalletConnectClient } from "services/walletServices";
import { Image } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
const ConnectModal = ({ closeModel }) => {
  const dispatch = useAppDispatch();

  const handleWeb3MetaMask = async () => {
    dispatch(loadBlockchain());
  };

  const { connect } = useWalletConnectClient();

  return (
    <MainModel>
      <ModelsData>
        <div className="cross-icon">
          <IoMdClose
            color="rgba(255, 255, 255, 0.4)"
            fontSize={30}
            fontWeight={700}
            cursor="pointer"
            onClick={closeModel}
          />
        </div>
        {/* <ConnectButton
          onClick={() => handleWeb3MetaMask()}
          className="metamask-btn"
        >
          <ConnectIcon src={Images.web3.metamask} alt="icon" />
          Meta Mask
        </ConnectButton> */}
        <div className="main-dev">
          <MainWrapper onClick={() => handleWeb3MetaMask()}>
            <Image src={Images.web3.metamask} />
            <h5>Metamask</h5>
          </MainWrapper>
        </div>
        {/* <ConnectButton
          onClick={() => {
            connect("eip155:5");
            closeModel();
          }}
          className="trustwallet-btn"
        >
          <ConnectIcon
            src={Images.web3.wallet}
            className="trustwallet-btn-img"
          />
          Wallet Connect
        </ConnectButton> */}
      </ModelsData>
    </MainModel>
  );
};

export default ConnectModal;
