import { tokenimg, tree4 } from "assets";
import { MainCol, MainContainer, MainRow } from "components/common";
import React from "react";
import { Image } from "react-bootstrap";

import {
  AirdropText,
  AirDropTitle,
  ImgDiv,
  ImgWrapper,
  MainSection,
  Text,
  Title,
  TopTitle,
} from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const TokenAirdropSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <AnimationOnScroll animateIn="animate__flipInX">
          <Title>
            ARGANACOIN
            <br />
            <p>Investir Dans ARG</p>
          </Title>
        </AnimationOnScroll>
        <ImgDiv>
          <Image src={tree4} />
        </ImgDiv>
        <MainRow className="main-row">
          <MainCol lg={7}>
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <TopTitle>JETON ARGANACOIN - ARG</TopTitle>
              <Text>
                <div style={{ marginLeft: "-1rem" }}>
                  <ul>
                    <li>
                      Un total de <span>180,000,000 de jetons ARG sera émis </span>.
                    </li>
                    <li>
                      <span>10%</span>&nbsp;de l'offre, soit <span>18,000,000 ARG</span>,est alloué à <span>ICO</span>.
                    </li>
                    <li>
                      La prévente des jetons se déroulera sur 24 mois, avec un une distribution de{" "}
                      <span>9,000,000</span> ARG par an.
                    </li>
                    <li>
                      La 1ère année,<span>7,000,000 ARG</span>  seront alloués au prix
                      préférentiel de <span>0.25 c/€ soit (0,27 USDT).</span>
                    </li>
                    <li>
                      La 2e année,<span>7,000,000 ARG</span> seront alloués au prix
                      préférentiel de <span>0.50 c/€ soit (0,54 USDT).</span>.
                      <br /> Le prix sera ajusté en fonction de l'avancement de la
                      projet et de la valeur ajoutée.
                    </li>
                    <li>
                      Moins d'un quart de l'offre destinée à l'ICO, soit environ &nbsp;
                      <span>4,000,000 ARG</span> sur 24 mois, sera réservé aux{" "}
                      <span>500</span> premiers clients/investisseurs à un prix
                      promotionnel de :
                    </li>
                    <li>
                      <span>0,20 c/€ (0.22 USDT)</span> pendant les 12 premiers
                      mois.
                    </li>
                    <li>
                      <span>0,45 c/€ (0.49 USDT)</span> au cours des 12 derniers
                      mois.
                    </li>
                  </ul>
                </div>
              </Text>
            </AnimationOnScroll>
          </MainCol>
          <MainCol lg={5}>
            <AnimationOnScroll animateIn="animate__fadeInRight">
              <ImgWrapper>
                <Image src={tokenimg} fluid />
              </ImgWrapper>
            </AnimationOnScroll>
          </MainCol>
        </MainRow>
        <AnimationOnScroll animateIn="animate__fadeInUp">
          <AirDropTitle>AIRDROP ET PROMOTION</AirDropTitle>
          <AirdropText>
            <ul>
              <li>
                Un quart de l'offre, soit environ &nbsp;
                <span>4,000,000 ARG</span> est réservé aux{" "}
                <span>500</span> premiers clients/investisseurs à un tarif préférentiel.
              </li>
              <li>
                Les investisseurs et clients participant et soutenant Arganacoin seront récompensés
                pour leurs efforts de communication sur les réseaux sociaux et
                la confiance qu'ils accordent au projet.
              </li>
              <li>
                Des jetons <span>ARG</span>&nbsp;seront utilisés pour les campagnes de
                marketing, les partenariats et les initiatives promotionnelles
                visant à accroître la <span>visibilité</span> du projet Arganacoin.
              </li>
            </ul>
          </AirdropText>
        </AnimationOnScroll>
      </MainContainer>
    </MainSection>
  );
};

export default TokenAirdropSection;
