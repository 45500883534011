const environment = {
  BACKEND_BASE_URL: "http://localhost:8080",
  CONTRACT_ADDRESS: "0x1002570a9b51b1bb3B4240435C64eD2744Ea4a21",
  MORALIS_APP_ID: "Cong0Rh8UwDPV5HC4U0nCPxiwEz5PAxeFLP2f3i0",
  MORALIS_SERVER_URL: "https://nbnob0befkpo.usemoralis.com:2053/server",
  UINT_256_MAX:
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  USDT: {
    11155111: "0x922587676577fC880B85E1db182e465E34D92906",
    1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  },
  ARGANACOIN: {
    11155111: "0xcBE803c87E30d55Fe45b44ACc40EBFEC4746Cf83",
    1: "0xc7610a6Fb69C7cCb7620E740449E767Dd0c58391",
  },
  ARGSALE: {
    11155111: "0x4705dadF55c9ec3828678D247a3aDC3dEab9Cbeb",
    1: "0x1002570a9b51b1bb3B4240435C64eD2744Ea4a21",
  },

  START_TIME: "December 25, 2024 00:00:00 GMT+0000",
  UNIX_START_TIME: 1703444400,
  DEFAULT_CHAIN: 1,
};

export default environment;
