import styled from "styled-components";

export const MainSection = styled.section`
  .container {
    padding-bottom: 0rem;
  }
`;

export const ImgDiv = styled.div`
  text-align: end;

  @media (max-width: 990px) {
    display: none;
  }
`;

export const Title = styled.h5`
  margin-top: -6rem;

  color: rgba(61, 64, 68, 0.35);
  text-align: center;
  font-family: "Zen Dots";
  font-size: 58.917px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 58.917px */
  text-transform: capitalize;
  @media (max-width: 990px) {
    margin-top: 2rem;
  }

  @media (max-width: 767px) {
    font-size: 40px;
  }

  p {
    color: #5ea624;
    text-align: center;
    font-family: "Zen Dots";
    font-size: 29.459px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 29.459px */
    text-transform: capitalize;
    transform: translateY(-1rem);

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  &.title2 {
    margin-top: -1.5rem;

    @media (max-width: 990px) {
      margin-top: 5rem;
    }
  }
`;

export const TextDiv = styled.div`
  position: absolute;
  &.first-div {
    top: 65px;
    left: 8.5%;

    @media (max-width: 1399.98px) {
      left: 9%;
    }

    @media (max-width: 1199.98px) {
      top: 30px;
    }
  }

  &.second-div {
    top: 300px;
    left: 28%;

    @media (max-width: 1399.98px) {
      top: 270px;
      left: 32.5%;
    }

    @media (max-width: 1199.98px) {
      top: 230px;
    }
  }

  &.third-div {
    top: 47px;
    left: 45.5%;
    @media (max-width: 1399.98px) {
      left: 55.5%;
    }
    @media (max-width: 1199.98px) {
      top: 20px;
    }
  }

 &.fourth-div {
    top: 295px;
    left: 64%;

    @media (max-width: 1399.98px) {
      top: 270px;
      left: 78%;
    }

    @media (max-width: 1199.98px) {
      top: 230px;
    }
  }
  

  &.fifth-div { 
    top: 06px;  
    left: 82%;   
    @media (max-width: 1399.98px) {
      top: 80px;
      left: 55%;
    }
  } 
    &.sixth-div { 
    top: 200px;  
    left: 55%;   
    @media (max-width: 1399.98px) {
      top: 80px;
      left: 55%;
    }
  }

`;

export const HeaderTitle = styled.h5`
  color: #5ea624;
  font-family: "Zen Dots";
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;

  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-size: 14.423px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.029px; /* 125% */
  text-transform: capitalize;
  white-space: pre-line;
  margin-bottom: 0.7rem;
  @media (max-width: 767px) {
    font-size: 12px;
    /* white-space: wrap; */
  }
`;

export const MainWrapper = styled.div`
  position: relative;
  /* margin-top: 6rem; */

  @media (max-width: 991.98px) {
    display: none;
  }
`;

export const ResponsiveRoadmapWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 4rem;

  /* img {
    width: 349px;

    @media (max-width: 767.98px) {
      width: 250px;
    }
  } */

  @media (min-width: 992px) {
    display: none;
  }
`;

export const MobileTextDiv = styled.div`
  position: absolute;

  &.first-div {
    top: 120px;
    left: 22%;

    @media (max-width: 767.98px) {
      top: 120px;
      left: 22%;
    }

    @media (max-width: 575.98px) {
      top: 120px;
      left: 28%;
    }
  }

  &.second-div {
    top: 420px;
    left: 20%;

    @media (max-width: 767.98px) {
      top: 420px;
      left: 22%;
    }

    @media (max-width: 575.98px) {
      top: 420px;
      left: 28%;
    }
  }

  &.third-div {
    top: 730px;
    left: 20%;

    @media (max-width: 767.98px) {
      top: 720px;
      left: 22%;
    }

    @media (max-width: 575.98px) {
      top: 730px;
      left: 28%;
    }
  }

  &.fourth-div {
    top: 1020px;
    left: 20%;

    @media (max-width: 767.98px) {
      top: 1030px;
      left: 22%;
    }

    @media (max-width: 575.98px) {
      top: 1030px;
      left: 28%;
    }
  }

  &.fifth-div {
    top: 1320px;
    left: 20%;

    @media (max-width: 767.98px) {
      top: 1220px;
      left: 55%;
    }

    @media (max-width: 575.98px) {
      top: 1300px;
      left: 28%;
    }
  }

   &.sixth-div { 
    top: 1550px;  
    left: 32%;
    @media (max-width: 767.98px) {
      top: 1100px;
      left: 55%;
    }
    @media (max-width: 575.98px) {
      top: 1150px;
      left: 28%;
    }
  }
`;

export const ImgWrapper = styled.div`
  text-align: center;
`;