import { MainContainer } from "components/common";
import React from "react";
import { ContactBtn, ImgWrapper, MainSection, Text } from "./elements";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Image } from "react-bootstrap";
import { helpimg } from "assets";
const HelpSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <div className="maindiv">
        <Image src={helpimg} fluid/>
        <div className="seconddiv">
        <AnimationOnScroll animateIn="animate__fadeInUp">
            <Text>Besoin d’Aides ?</Text>
            <ContactBtn>contact@arganacoin.io</ContactBtn>       
        </AnimationOnScroll>
        </div>
        </div>
      </MainContainer>
    </MainSection>
  );
};

export default HelpSection;
