import { joinimg } from "assets";
import styled from "styled-components";
import "animate.css";
export const MainSection = styled.section`
  padding-bottom: 3rem;
  .container {
  }
`;

export const MainWrapper = styled.div`
  background-image: url(${joinimg});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 438px;
`;

export const Title = styled.h5`
  color: #5ea624;
  font-family: "Zen Dots";
  font-size: 57px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 5.7px;
  margin-top: 4rem;

  @media (max-width: 767px) {
    font-size: 47px;
    text-align: center;
  }
`;

export const SocialIconDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin-top: 10rem;

  @media (max-width: 767px) {
    margin-top: 4rem;
  }
  img {
    width: 40.378px;
    height: 42.901px;
    object-fit: contain;
    cursor: pointer;
    &:hover {
      animation-name: heartBeat;
      animation-duration: 1s;
    }
  }
`;

export const ImgDiv = styled.div`
  text-align: center;
  margin-top: 4rem;

  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;
