import { Arganacoin } from "assets";
import { MainContainer } from "components/common";
import React from "react";
import { MainSection, VideoWrapper } from "./elements";

const VideoSection = () => {
  return (
    <MainSection>
      <MainContainer>
        <VideoWrapper>
          <video src={Arganacoin} loop controls></video>
          {/* <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/953689591?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="ECOSYSTEME ARGANACOIN - fr"
            ></iframe>
          </div> */}

        </VideoWrapper>
      </MainContainer>
    </MainSection>
  );
};

export default VideoSection;
