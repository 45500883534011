import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
// import { MainModel } from "components/common";
import { mainModel } from "store/redux/slices/helperSlices/modelSlice";
import { switchNetwork } from "store/redux/slices/wallet3Connect/web3ConnectSlice";
import { PriceConvertorHook } from "hooks/priceHooks";
import { networkObj } from "../data";
import { ContractUtility } from "utility/contract-utility";
import { MainModel } from "../common";
import NavbarComp from "./navbar";
import HeroComp from "./hero";
import CASection from "./caSection";
import AboutComp from "./aboutSection";
import RoadmapSection from "./roadmapSection";
import TokenAirdropSection from "./tokenAndAirdropSection";
import TokennomicsSection from "./tokenmoicsSection";
import HelpSection from "./helpSection";
import JoinSection from "./joinSection";
import FooterSection from "./footerSection";
import { scrollimg } from "assets";
import { Image } from "react-bootstrap";
import VideoSection from "./videoSection";
import IsomatricComp from "./isometricSection";

const HomeCom = () => {
  const dispatch = useAppDispatch();

  const [connectModel, setConnectModel] = useState(false);

  const { web3, account, chainId } = useAppSelector(
    (state) => state.web3Connect
  );

  // const {convertedPrice} = PriceConvertorHook({ amount: 1, id: "2" })

  const handleModelOpen = () => {
    setConnectModel(true);
    dispatch(mainModel(true));
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        className={scrolled ? "arrow" : "arrow-hidden"}
        onClick={handleClick}
      >
        <Image src={scrollimg} />
      </div>

      <HeroComp />
      <CASection />
      <VideoSection />
      <AboutComp />
      <RoadmapSection />
      <TokenAirdropSection />
      <TokennomicsSection />
      <IsomatricComp />
      <HelpSection />
      <JoinSection />
      <FooterSection />
      {/* <MainModel connectModel={connectModel} />
      <button onClick={handleModelOpen}>Connect</button>

      {web3 ? (
        <select
          value={ContractUtility.getProtocol(chainId)}
          onChange={(e) => switchNetwork(web3, e.target.value)}
        >
          {networkObj.map((network, i) => {
            return (
              <option key={i} value={network.value}>
                {network.name}
              </option>
            );
          })}
        </select>
      ) : (
        ""
      )}
      <p>account: {account}</p> */}
    </div>
  );
};

export default HomeCom;
