import { herobg } from "assets";
import styled from "styled-components";

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const MainSection = styled.section`
  background-image: url(${herobg});
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */

  .main-right-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -2rem;

    @media (max-width: 990px) {
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-height: 700px) {
      margin-top: 2rem;
    }
  }
`;

export const TopTitleDiv = styled.h5`
  color: #fff;
  text-shadow: 0px 4px 10px rgba(94, 166, 36, 0.36);
  font-family: "Zen Dots";
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 93px */
  letter-spacing: -0.682px;

  @media (max-width: 990px) {
    font-size: 30px;
    text-align: center;
    margin-top: 3rem;
  }
`;

export const Text = styled.p`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem;
  /* width: 659px; */
  text-transform: uppercase;
  text-align: start;

  @media (max-width: 767px) {
    text-align: center;
    font-size: 16px;
    line-height: normal;
  }
  @media (max-width: 990px) {
    width: 100%;
    text-align: center;
  }
`;

export const TimeLabel = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 13.873px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.31px; /* 150% */
  letter-spacing: 0.158px;
  margin-top: 1rem;
`;

export const TimerDiv = styled.div`
  h5 {
    color: #ece8f0;
    font-family: "Zen Dots";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 55.948px; /* 215.184% */
    letter-spacing: 0.35px;
    width: 2.5rem;
    span {
      color: #ece8f0;
      font-size: 0.6825rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.17063rem; /* 25% */
      letter-spacing: 0.00644rem;
    }
  }
`;

export const SliderLabel = styled.p`
  color: #fff;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 16.873px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.31px; /* 150% */
  letter-spacing: 0.158px;
  margin-top: 1rem;
`;

export const AmountText = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 16.873px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.31px; /* 150% */
  margin-top: -0.5rem;
`;

export const SliderDiv = styled.div`
  .ant-slider-handle {
    width: 1.30813rem;
    height: 1.30813rem;
    margin-top: -6px;
    border-radius: 10.213px;
    background: #5ea624;

    border: none !important;
  }
  .ant-slider-handle:hover .ant-slider-tooltip {
    display: none !important;
  }

  /* Hide the percentage value on handle move */
  .ant-slider-tooltip-placement-top .ant-slider-tooltip-placement-topLeft,
  .ant-slider-tooltip-placement-top .ant-slider-tooltip-placement-topRight {
    display: none !important;
  }

  .ant-slider-rail {
    border-radius: 17.761px;
    background: rgba(217, 255, 232, 0.48);
    height: 0.56875rem;
  }

  .ant-slider-track {
    border-radius: 17.761px;
    background: rgba(217, 255, 232, 0.48);
  }

  .ant-slider:hover .ant-slider-track {
    background: rgba(217, 255, 232, 0.48) !important;
  }

  .ant-slider:hover .ant-slider-rail {
    background: rgba(217, 255, 232, 0.48) !important;
  }
`;

export const ValueDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    color: #fff;
    font-size: 10.657px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.985px; /* 150% */
    letter-spacing: 0.1px;
  }

  p {
    color: #fff;
    font-size: 10.657px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.985px; /* 150% */
    letter-spacing: 0.1px;
  }
`;

export const Btn = styled.h5`
  color: #fff;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0rem;
`;

export const BtnDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;
  border-radius: 31.423px;
  border: 1px solid #5ea624;
  height: 36px;
  justify-content: center;
  margin-top: 0.8rem;
  cursor: pointer;
  img {
    width: 24px;
    height: 22px;
  }

  &.btn-div {
    margin-top: 1rem;
  }
`;

export const LastDiv = styled.div`
  margin-top: 2rem;
  p {
    color: #fff;
    text-align: center;
    font-size: 0.6825rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.02375rem; /* 150% */
    letter-spacing: 0.00644rem;
  }

  .icon-div {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
    margin-top: -0.7rem;
  }
`;

export const RightDiv = styled.div`
  border-radius: 10px;
  border: 1px solid #5ea624;
  background: rgba(11, 31, 6, 0.39);
  width: 357px;
  min-height: 555px;
  padding: 0px 25px;

  @media (max-width: 990px) {
    width: 100%;
  }
`;

export const ImgDiv = styled.div`
  text-align: center;
  margin-top: -3.5rem;
`;

export const ToknomicsBtn = styled.button`
  color: #fff;
  text-align: center;
  font-family: "Zen Dots";
  font-size: 14.914px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 39.054px;
  width: 174px;
  height: 42.257px;
  border: 1px solid #5ea624;
  background-color: transparent;
  margin-top: 0.7rem;
  transition: 0.3s ease-in;
  &:hover {
    background: #6bbb2b;
    animation-name: heartBeat;
    animation-duration: 1s;
  }

  @media (max-width: 990px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const FormDiv = styled.div`
  margin-top: 1rem;
  p {
    color: #fff;
    text-align: center;
    font-weight: 400;
    font-family: "Zen Dots";
  }

  .form-control {
    background-color: transparent;
    border-radius: 31.423px;
    border: 1px solid #5ea624;
    color: #fff;

    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    &::placeholder {
      color: #fff;
      font-weight: 200;
      font-size: 14px;
    }
  }
`;

export const CostDiv = styled.div`
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-family: "Zen Dots";
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  img {
    width: 15px;
    height: 15px;
    object-fit: contain;
  }
`;
